import React from 'react'
import { VStack, Widget } from '@revolut/ui-kit'
import { CycleOption } from '@src/interfaces/selectors'
import { FilterByInterface } from '@src/interfaces/data'
import { CycleFilterButton } from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'
import { CYCLE_ID_QUERY_PARAM_KEY } from '@src/features/ReviewCycle/Analytics/PerformanceReviewsAnalytics'

interface Props {
  cycleId: string | number | undefined
  cycleOptions: CycleOption[]
  handleFilterCycle: (filters: FilterByInterface) => void
}

export const AnalyticsOverview = ({
  cycleId,
  cycleOptions,
  handleFilterCycle,
}: Props) => {
  return (
    <Widget p="s-16">
      <VStack space="s-16">
        <CycleFilterButton
          selector={() => Promise.resolve({ options: cycleOptions })}
          onFilterChange={handleFilterCycle}
          columnName={CYCLE_ID_QUERY_PARAM_KEY}
          filter={[
            {
              columnName: CYCLE_ID_QUERY_PARAM_KEY,
              filters: cycleId ? [{ id: cycleId, name: `${cycleId}` }] : [],
            },
          ]}
        />
      </VStack>
    </Widget>
  )
}
