import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { Box } from '@revolut/ui-kit'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { HiringStageDetails } from '@src/features/JobPostingFlow/HiringProcess/HiringStageDetails'
import { Scorecard } from '@src/features/JobPostingFlow/HiringProcess/Scorecard/Scorecard'

interface Props {
  stage: HiringProcessInterface
  onClose: VoidFunction
}

enum Tabs {
  Details = 'Details',
  Evaluation = 'Evaluation scorecard',
}

export const HiringStageSidebar = ({ stage, onClose }: Props) => {
  const { tabBar, currentTab } = useTabBarSwitcher({
    tabs: [Tabs.Details, Tabs.Evaluation],
  })

  return (
    <SideBar variant="wide" title={stage.title} isOpen onClose={onClose}>
      <Box mb="s-16">{tabBar}</Box>

      {currentTab === Tabs.Details && <HiringStageDetails stage={stage} />}
      {currentTab === Tabs.Evaluation && (
        <Scorecard key={stage.id} scorecard={stage.job_posting_scorecard} />
      )}
    </SideBar>
  )
}
